import React from "react";
import { styled, Box, Typography } from "@material-ui/core";
import theme from "../styles/theme";
import { useStyles } from "../styles/formStyles";
import { KiteIcon } from "@kite/react-kite";
import { SINGLESIGNON } from "../utils/types";

interface PasswordRulesContainerProps {
  password: string;
  confirmPassword: string;
}

export const DInfoSection = styled("div")({
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(4),
  marginBottom: theme.spacing(1.5),
  marginTop: theme.spacing(1.5),
  backgroundColor: "#EBF7FF",
});

const SResetListItem = styled("li")({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  color: "#001B33",
});

const SArrowContainer = styled("div")({
  height: "0",
  width: "30px",
  transform: "rotate(-45deg)",
  zIndex: 0.1,
  position: "relative",
  left: "30px",
});

const ArrowIcon = () => {
  return (
    <SArrowContainer>
      <KiteIcon icon="ki-location-arrow-f" size="small" fill="#EBF7FF" />
    </SArrowContainer>
  );
};

const PasswordRulesContainer = ({
  password,
  confirmPassword,
}: PasswordRulesContainerProps) => {
  const classes = useStyles();

  const finalMatch = password === confirmPassword && password.length > 0;

  return (
    <>
      <ArrowIcon />
      <DInfoSection>
        <Box className={classes.resetRules}>
          <ul className={classes.resetRulesList}>
            <Box style={{ paddingBottom: "15px" }}>
              <li>
                <Typography variant="h6">
                  {SINGLESIGNON.passwordRulesHeading}
                </Typography>
              </li>
              <SResetListItem
                className={
                  password.length >= 8
                    ? classes.bulletMatch
                    : classes.bulletFail
                }
              >
                <Typography
                  className={classes.passwordRuleBulletText}
                  variant="subtitle2"
                >
                  {SINGLESIGNON.passwordLengthRule}
                </Typography>
              </SResetListItem>
            </Box>
            <li>
              <Typography variant="h6">
                {SINGLESIGNON.andMustContain}
              </Typography>
            </li>
            <SResetListItem
              className={
                /[A-Z]/.test(password)
                  ? classes.bulletMatch
                  : classes.bulletFail
              }
            >
              <Typography
                className={classes.passwordRuleBulletText}
                variant="subtitle2"
              >
                {SINGLESIGNON.upper}
              </Typography>
            </SResetListItem>
            <SResetListItem
              className={
                /[a-z]/.test(password)
                  ? classes.bulletMatch
                  : classes.bulletFail
              }
            >
              <Typography
                className={classes.passwordRuleBulletText}
                variant="subtitle2"
              >
                {SINGLESIGNON.lower}
              </Typography>
            </SResetListItem>
            <SResetListItem
              className={
                /\d/.test(password) ? classes.bulletMatch : classes.bulletFail
              }
            >
              <Typography
                className={classes.passwordRuleBulletText}
                variant="subtitle2"
              >
                {SINGLESIGNON.number}
              </Typography>
            </SResetListItem>
            <SResetListItem
              className={
                /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/.test(password)
                  ? classes.bulletMatch
                  : classes.bulletFail
              }
            >
              <Typography
                className={classes.passwordRuleBulletText}
                variant="subtitle2"
              >
                {SINGLESIGNON.special}
              </Typography>
            </SResetListItem>
            <SResetListItem
              className={finalMatch ? classes.bulletMatch : classes.bulletFail}
            >
              <Typography
                className={classes.passwordRuleBulletText}
                variant="subtitle2"
              >
                {SINGLESIGNON.passwordMatch}
              </Typography>
            </SResetListItem>
          </ul>
        </Box>
      </DInfoSection>
    </>
  );
};

export default PasswordRulesContainer;
