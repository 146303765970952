export const COGNITO = {
  REGION: "us-east-1",
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
};

export const OneSpotConfig = {
  REIGON: "us-east-1",
  USER_POOL_ID: process.env.REACT_APP_ONESPOT_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_ONESPOT_CLIENT_ID,
};

export const ClientPortalConfig = {
  REIGON: "us-east-1",
  USER_POOL_ID: process.env.REACT_APP_CLIENT_PORTAL_USER_POOL_ID,
  APP_CLIENT_ID: process.env.REACT_APP_CLIENT_PORTAL_CLIENT_ID,
};

export const API_URLS = {
  authCodeURL: process.env.REACT_APP_AUTH_CODE_URL,
};

export const UAT_CONFIG = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_BAnlzx69k",
  APP_CLIENT_ID: "7ku05acdbsl9lhc4b1kbrej3ao",
};

export const UAT_API_URLS = {
  authCodeURL: "https://qq64m0lzkh.execute-api.us-east-1.amazonaws.com/prod/login",
};