import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import { useInput } from "./../utils/forms";
import { validatePassword } from "./../utils/common";
import { SINGLESIGNON } from "../utils/types";
import { Auth } from "aws-amplify";
import Button from "@material-ui/core/Button";
import {
  Typography,
  Divider,
  Box,
  InputAdornment,
  IconButton,
  Grid,
} from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useStyles } from "../styles/formStyles";
import theme from "../styles/theme";
import { KiteIcon, KiteTextInput } from "@kite/react-kite";
import { InfoIcon } from "./kiteComponents/IconComponents";
import { PageLoader } from "../themeComponents/Loader";
import PasswordRulesContainer, { DInfoSection } from "./PasswordRulesContainer";

const Field = styled(TextField)({
  margin: "10px 0",
});

const DHelpSection = styled("div")({
  marginTop: theme.spacing(4),
});

const Visibility = () => {
  return <KiteIcon fill="#000000" icon="ki-eye" size="1.5rem" />;
};
const VisibilityOff = () => {
  return <KiteIcon fill="#000000" icon="ki-eye-cross" size="1.5rem" />;
};

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [screenState, setScreenState] = React.useState(1);
  const classes = useStyles();
  const history = useHistory();
  const [destinationEmail, setDestinationEmail] = useState<any>(null);
  const [forgotPasswordError, setForgotPasswordError] = useState<any>(null);
  const [resendResponse, setResendResponse] = React.useState<any>(null);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const resetRequired = searchParams.get("resetRequired") === "true";
  const savedUsername = searchParams.get("username");
  const callbackUrl = searchParams.get("callbackUrl");
  const clientId = searchParams.get("clientId");

  const { value: username, bind: bindUsername } = useInput(savedUsername || "");
  const {
    value: password,
    bind: bindPassword,
    reset: resetPassword,
  } = useInput("");
  const {
    value: confirmPassword,
    bind: bindNewPassword,
    reset: resetConfirmPassword,
  } = useInput("");
  const { value: code, bind: bindCode, reset: resetCode } = useInput("");

  const handleSubmit = async (e: React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    setLoading(true);
    callbackUrl && localStorage.setItem("callbackUrl", callbackUrl);
    clientId && localStorage.setItem("clientId", clientId);

    if (resetRequired) {
      try {
        await Auth.forgotPassword(savedUsername || username).then((res) => {
          let email = res.CodeDeliveryDetails.Destination;
          let modEmail = email.replace(/@/, "*****@");
          setDestinationEmail(modEmail + "*.com");
        });
        setScreenState(2);
      } catch (error: any) {
        setForgotPasswordError(removeUserMigrationError(error.message));
      }
    } else {
      try {
        await Auth.forgotPassword(savedUsername || username).then((res) => {
          let email = res.CodeDeliveryDetails.Destination;
          let modEmail = email.replace(/@/, "*****@");
          setDestinationEmail(modEmail + "*.com");
          setForgotPasswordError(null);
        });
        setScreenState(2);
      } catch (error: any) {
        switch (error.name) {
          case "UserNotFoundException":
            setScreenState(2);
            break;
          default:
            setForgotPasswordError(removeUserMigrationError(error.message));
            break;
        }
      }
    }
    setLoading(false);
  };

  const removeUserMigrationError = (error: string) => {
    const userExistPolicy = "UserMigration failed with error ";
    const strippedError = error.replace(userExistPolicy, "");
    return strippedError;
  };

  const handleResend = async () => {
    try {
      await Auth.forgotPassword(savedUsername || username);
      setResendResponse("Successfully Resent Verification Code");
    } catch (error: any) {
      switch (error.name) {
        case "UserNotFoundException":
          setResendResponse(
            "If you have an account, a code will be sent to your email."
          );
          break;
        default:
          setResendResponse(removeUserMigrationError(error.message));
          break;
      }
    }
  };

  const handleSetScreenToResetPassword = () => {
    if (!destinationEmail) {
      setResendResponse("");
      setForgotPasswordError(
        "The verification code provided was not valid. Please return to the previous page and try again."
      );
    } else {
      setScreenState(3);
    }
  };

  const handleResetPassword = async (
    e: React.SyntheticEvent<Element, Event>
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(
        savedUsername || username,
        code,
        password
      );
      setScreenState(4);
    } catch (error: any) {
      setForgotPasswordError(error.message);
    }
    setLoading(false);
  };

  const handleBackToLogin = () => history.push("/login");

  const handleRetryForgotPasswordCode = () => {
    setScreenState(2);
    setForgotPasswordError(null);
    setResendResponse(null);
    resetPassword();
    resetConfirmPassword();
    resetCode();
  };

  const disabledSendCode = !username;
  const disabledSendCodeIfResetRequired = resetRequired && !savedUsername;

  return (
    <div className={classes.root}>
      {screenState === 1 && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Typography className={classes.cardAccentText}>
            {SINGLESIGNON.accent}
          </Typography>
          <Typography className={classes.cardText} variant="h1">
            {SINGLESIGNON.forgotPassword}
          </Typography>
          <Typography className={classes.cardSubText} variant="subtitle1">
            {SINGLESIGNON.verificationCodeWillBeSent}
          </Typography>
          <DInfoSection>
            <Box display="flex" flexDirection="row" alignItems="center">
              <KiteIcon
                className={classes.infoSectionIcon}
                fill="#0073D1"
                icon="ki-info-circle-f"
                size="1rem"
              />
              <Box
                sx={{
                  width: "25rem",
                  marginLeft: theme.spacing(2),
                  paddingBottom: theme.spacing(2),
                  fontSize: "12px",
                }}
              >
                <Typography
                  className={
                    classes.cardSubText + " " + classes.infoSectionText
                  }
                  variant="subtitle1"
                >
                  {SINGLESIGNON.loginToSupportedApps}
                </Typography>
              </Box>
            </Box>
          </DInfoSection>
          {resetRequired ? (
            <KiteTextInput
              disabled
              className={classes.input + " " + classes.disabledInput}
              errorMessage=""
              id=""
              label="Username"
              name=""
              value={savedUsername || ""}
            />
          ) : (
            <KiteTextInput
              className={classes.input}
              errorMessage=""
              id=""
              label="Username"
              name=""
              {...bindUsername}
            />
          )}
          {forgotPasswordError && (
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: theme.spacing(2) }}
              wrap="nowrap"
            >
              <Grid
                item
                style={{ width: "17px", marginRight: "5px", flexShrink: 0 }}
              >
                <InfoIcon
                  fill="#D6312B"
                  size="10px"
                  icon="ki-caution-circle-f"
                />
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.errorMessage}
                >
                  {forgotPasswordError}
                </Typography>
              </Grid>
            </Grid>
          )}
          {resetRequired && (
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginBottom: theme.spacing(2) }}
            >
              <Grid item style={{ width: "17px", marginRight: "5px" }}>
                <InfoIcon
                  fill="#D6312B"
                  size="10px"
                  icon="ki-caution-circle-f"
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  className={classes.errorMessage}
                >
                  {SINGLESIGNON.passwordResetRequired}
                </Typography>
              </Grid>
            </Grid>
          )}
          {resetRequired ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={loading || disabledSendCodeIfResetRequired}
              className={classes.button + " " + classes.medWidthButton}
            >
              <Typography className={classes.buttonSubText}>
                {SINGLESIGNON.sendVerificationCode}
              </Typography>
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={loading || disabledSendCode}
              className={classes.button + " " + classes.medWidthButton}
            >
              <Typography className={classes.buttonSubText}>
                {SINGLESIGNON.sendVerificationCode}
              </Typography>
            </Button>
          )}
          <DHelpSection>
            <Divider className={classes.divider} />
            <Typography className={classes.helpSection}>
              <span className={classes.linkSpan}>
                {" "}
                {SINGLESIGNON.needHelp}{" "}
              </span>{" "}
              <Link to="/contact-us" className={classes.link}>
                {SINGLESIGNON.contactUs}
              </Link>
            </Typography>
          </DHelpSection>
        </form>
      )}
      {screenState === 2 && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Typography className={classes.cardAccentText}>
            {SINGLESIGNON.accent}
          </Typography>
          <Typography className={classes.cardText} variant="h1">
            {SINGLESIGNON.verifyIdentity}
          </Typography>
          {destinationEmail ? (
            <Typography className={classes.cardSubText} variant="subtitle1">
              {SINGLESIGNON.enterVerificationCode}{" "}
              <span className={classes.maskedEmail}>{destinationEmail}</span>
            </Typography>
          ) : (
            <Typography className={classes.cardSubText} variant="subtitle1">
              {SINGLESIGNON.defaultVerificationCodeMesssage}
            </Typography>
          )}
          <KiteTextInput
            className={classes.input}
            errorMessage=""
            id=""
            label="Verification Code"
            name=""
            {...bindCode}
          />
          {resendResponse && (
            <Grid container direction="row" alignItems="center" wrap="nowrap">
              <Grid
                item
                style={{
                  width: "20px",
                  marginRight: theme.spacing(2),
                  flexShrink: 0,
                }}
              >
                <InfoIcon fill="#0073D1" size="10px" icon="ki-info-circle-f" />
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.successMessage}
                >
                  {resendResponse}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              disabled={loading || !code || forgotPasswordError}
              onClick={handleSetScreenToResetPassword}
              className={classes.button + " " + classes.smallWidthButton}
            >
              <Typography className={classes.buttonSubText}>
                {SINGLESIGNON.verify}
              </Typography>
            </Button>
            <Typography
              className={classes.secondaryButtonContent}
              variant="body2"
              onClick={handleResend}
            >
              {" "}
              {SINGLESIGNON.resendCode}{" "}
            </Typography>
          </Box>
          {forgotPasswordError && (
            <Grid
              container
              direction="row"
              alignItems="center"
              wrap="nowrap"
              style={{ marginTop: theme.spacing(5) }}
            >
              <Grid
                item
                style={{
                  width: "20px",
                  marginRight: theme.spacing(2),
                  marginBottom: theme.spacing(5),
                  flexShrink: 0,
                }}
              >
                <InfoIcon
                  fill="#D6312B"
                  size="10px"
                  icon="ki-caution-circle-f"
                />
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.errorMessage}
                >
                  {forgotPasswordError}
                </Typography>
              </Grid>
              <Grid
                item
                className={classes.forgotPasswordCodeError}
                style={{ marginBottom: theme.spacing(6) }}
              >
                <Typography
                  className={
                    classes.buttonSubText + " " + classes.secondaryButtonContent
                  }
                  onClick={() => handleRetryForgotPasswordCode()}
                >
                  {SINGLESIGNON.goBack}
                </Typography>
              </Grid>
            </Grid>
          )}
          <DHelpSection>
            <Divider className={classes.divider} />
            <Typography className={classes.helpSection}>
              <span className={classes.linkSpan}>
                {" "}
                {SINGLESIGNON.needHelp}{" "}
              </span>{" "}
              <Link to="/contact-us" className={classes.link}>
                {SINGLESIGNON.contactUs}
              </Link>
            </Typography>
          </DHelpSection>
        </form>
      )}
      {screenState === 3 && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Typography className={classes.cardAccentText}>
            {SINGLESIGNON.accent}
          </Typography>
          <Typography className={classes.cardText} variant="h1">
            {SINGLESIGNON.changePassword}
          </Typography>
          <Box>
            <Field
              fullWidth
              label="New Password"
              {...bindPassword}
              variant="outlined"
              InputLabelProps={{ shrink: true, style: { color: "black" } }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <PasswordRulesContainer
            password={password}
            confirmPassword={confirmPassword}
          />
          <Box>
            <Field
              fullWidth
              label="Confirm New Password"
              {...bindNewPassword}
              variant="outlined"
              InputLabelProps={{ shrink: true, style: { color: "black" } }}
              type={showConfirmPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={
              !validatePassword(password) ||
              password !== confirmPassword ||
              forgotPasswordError
            }
            onClick={handleResetPassword}
            className={classes.button + " " + classes.smallWidthButton}
          >
            <Typography className={classes.buttonSubText}>
              {SINGLESIGNON.resetPassword}
            </Typography>
          </Button>
          {forgotPasswordError && (
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{
                marginBottom: theme.spacing(2),
                marginTop: theme.spacing(5),
              }}
            >
              <Grid item style={{ width: "17px", marginRight: "5px" }}>
                <InfoIcon
                  fill="#D6312B"
                  size="10px"
                  icon="ki-caution-circle-f"
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  className={classes.errorMessage}
                >
                  {forgotPasswordError}
                </Typography>
              </Grid>
              <Grid item className={classes.forgotPasswordCodeError}>
                <Typography
                  className={
                    classes.buttonSubText + " " + classes.secondaryButtonContent
                  }
                  onClick={() => handleRetryForgotPasswordCode()}
                >
                  {SINGLESIGNON.goBack}
                </Typography>
              </Grid>
            </Grid>
          )}
          <DHelpSection>
            <Divider className={classes.divider} />
            <Typography className={classes.helpSection}>
              <span className={classes.linkSpan}>
                {" "}
                {SINGLESIGNON.needHelp}{" "}
              </span>{" "}
              <Link to="/contact-us" className={classes.link}>
                {SINGLESIGNON.contactUs}
              </Link>
            </Typography>
          </DHelpSection>
          {loading && <PageLoader />}
        </form>
      )}
      {screenState === 4 && (
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={handleSubmit}
          className={classes.form}
        >
          <Typography className={classes.cardAccentText}>
            {SINGLESIGNON.accent}
          </Typography>
          <Typography className={classes.cardText} variant="h1">
            {SINGLESIGNON.passwordResetSuccess}
          </Typography>
          <Typography className={classes.cardSubText} variant="subtitle1">
            {SINGLESIGNON.passwordResetSuccessSubText}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleBackToLogin}
            className={classes.button + " " + classes.smallWidthButton}
          >
            <Typography className={classes.buttonSubText}>
              {SINGLESIGNON.backToSignIn}
            </Typography>
          </Button>
          <DHelpSection>
            <Divider className={classes.divider} />
            <Typography className={classes.helpSection}>
              <span className={classes.linkSpan}>
                {" "}
                {SINGLESIGNON.needHelp}{" "}
              </span>{" "}
              <Link to="/contact-us" className={classes.link}>
                {SINGLESIGNON.contactUs}
              </Link>
            </Typography>
          </DHelpSection>
          {loading && <PageLoader />}
        </form>
      )}
    </div>
  );
};

export default ForgotPassword;
