import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom";
import ReactNotification from "react-notifications-component";
import { BrowserRouter } from "react-router-dom";
import "react-notifications-component/dist/theme.css";
import { ThemeProvider } from "@material-ui/core";
import theme from "./styles/spectrum-material-theme";

ReactDOM.render(
  <React.StrictMode>
    <ReactNotification />
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
