import { makeStyles } from "@material-ui/core/styles";
import theme from "./spectrum-material-theme";

export const loaderStyles = makeStyles(() => ({
    pageLoader: {
      position: "fixed",
      top: 0,
      left: 0,
      height: "100vh" /* to make it responsive */,
      width: "100vw" /* to make it responsive */,
      overflow: "hidden" /*to remove scrollbars */,
      zIndex: "99999" /*to make it appear on topmost part of the page */,
      backgroundColor: "#ffffff8c",
    },
    pageLoaderImg: {
      position: "relative",
      top: "calc(35% - 60px)",
      left: "calc(50% - 60px)",
    },
    componentLoader: {
      left: 0,
      overflow: "hidden" /*to remove scrollbars */,
      zIndex: "99999" /*to make it appear on topmost part of the page */,
      backgroundColor: "#FFFFFF",    
    },
    componentLoaderImg: {
      position: "absolute",
      left: "calc(50% - 30px)",
      top: "calc(50% + 30px)",    
    },
  }));

export const navStyles = makeStyles(() => ({
    root: {
    },
    outlinedContainer: {
      borderBottom: '1px solid lightGrey',
      paddingBottom: '5px',
      paddingRight: '8px',
    },
    backNavigationContainer: {
      paddingTop: theme.spacing(5),
    },
    link: {
      textDecoration: "none",
    },
    indicator: {
        color: '#0077AE',
        fontSize: '14px',
        paddingLeft: theme.spacing(1),
        transform: 'translateY(-4px)',
        '&:after': {
            textDecoration: 'none',
            borderBottom: 'none',
        },
    },
}));

