/* eslint-disable linebreak-style */
import React from "react";
import { loaderStyles } from "../styles/miscStyles";
import loadingGif from './loadingGif.gif';

export function PageLoader() {
  const classes = loaderStyles();
  return (
    <div id="loader" className={classes.pageLoader}>
      <img src={loadingGif} className={classes.pageLoaderImg} alt="Loading..." />
    </div>
  );
}

export function ComponentLoader() {
  const classes = loaderStyles();
  return (
    <div id="loader" className={classes.componentLoader}>
      <img src={loadingGif} className={classes.componentLoaderImg} alt="Loading..." />
    </div>
  );
}
