import { makeStyles } from '@material-ui/core/styles';
import theme from './spectrum-material-theme';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '528px',
        margin: '0 auto',
    },
    Paper: {
        paddingLeft: theme.spacing(13),
        paddingRight: theme.spacing(13),
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    cardAccentText: {
        fontSize: '18px',
        fontWeight: '700',
        lineHeight: '21px',
        letterSpacing: '1.5px',
        textAlign: 'left',
        color: '#00938C',
    },
    cardText: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    cardSubText: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(3),
        color: '#63738A',
        fontSize: '16px',
    },
    inputTextPlaceholder: {
    },
    authCodeGrantError: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(-4),
    },
    servicesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    servicesItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: theme.spacing(5),
    },
    serviceText: {
        fontSize: '12px',
        color: '#001B33',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '0.2rem',
    },
    input: {
        width: '100%',
        boxSizing: 'border-box',
        marginBottom: theme.spacing(4),
        '& label': {
            fontFamily: 'Spectrum Sans, Helvetica, Arial, sans-serif !important', // needs because of Kite
            fontWeight: '700',
            fontSize: '15px',
            lineHeight: '30px',
        },
        '& label::after': {
            content: '"*"',
            paddingLeft: '0.15rem',
            position: 'absolute',
            color: '#D6312B',
            fontWeight: '500',
            fontSize: '16px',
        },
        '& input': {
            width: '100%',
            fontFamily: 'Spectrum Sans, Helvetica, Arial, sans-serif !important', // needs because of Kite
            height: '40px',
            paddingLeft: '0.5rem',
            borderRadius: '2px',
            border: '1px solid #63738A',
            boxSizing: 'border-box', // Add this line
            '&:-webkit-autofill': {
              '-webkit-box-shadow': '0 0 0 1000px white inset !important',
              '-webkit-text-fill-color': '#000 !important',
            },
            '&:focus': {
              outline: 'none',
            },
          },
        '& button': {
            display: 'none',
        },
    },
    errorMessage: {
        color: '#D6312B',
        marginBottom: theme.spacing(1.2),
    },
    successMessage: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
    },
    spectrumSans: {
        fontWeight: 600,
    },
    spectrumSansLight: {
    },
    rememberMe: {
        width: 'fit-content',
        paddingBottom: theme.spacing(2),
    },
    rememberMeText: {
    },
    passwordRuleBulletText: {
        marginLeft: theme.spacing(3)
    },
    disabledInput: {
        '& *': {
            color: '#63738A',
            '&::selection': {
                backgroundColor: 'transparent',
            },
        },
    },
    forgotPasswordCodeError: {
        width: '30%',
        textAlign: 'center',
        marginBottom: theme.spacing(1.5),
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.dark,
        },
    },
    button: {
        padding: theme.spacing(5),
        marginBottom: theme.spacing(0),
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        borderRadius: '80px',
        color: 'white',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        '&:disabled': {
            color: theme.palette.grey[500],
            backgroundColor: theme.palette.grey[200],
        },
    },
    smallWidthButton: {
        width: '35%',
    },
    medWidthButton: {
        width: '50%',
        padding: theme.spacing(6),
    },
    buttonSubText: {
        fontSize: '14px',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    createNewAccountContainer: {
        width: '39%',
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(15),
        '&:hover': {
            cursor: 'pointer',
        },
    },
    createNewAccountContent: {
        color: theme.palette.primary.main,
        paddingRight: theme.spacing(2),
        fontWeight: '500',
        fontSize: '15px',
    },
    logoutContainer: {
        whiteSpace: 'nowrap',
        color: theme.palette.primary.main,
        paddingLeft: theme.spacing(1),
    },
    cancelContent: {
        color: theme.palette.primary.main,
        paddingLeft: theme.spacing(5),
        fontWeight: '700',
        transform: 'translateY(5px)',
        '&:hover': {
            cursor: 'pointer',
            color: theme.palette.primary.dark,
        },
    },
    secondaryButtonContent: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(6),
        transform: 'translateY(3px)',
        fontWeight: '500',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    newAccountButton: {
        width: '100%',
        padding: theme.spacing(4),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
        backgroundColor: 'white',
        color: 'black',
        borderColor: 'black',
        textTransform: 'none',   
    },
    resendButton: {
        width: '100%',
        padding: theme.spacing(4),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(2),
        backgroundColor: 'white',
        color: 'black',
        borderColor: 'black',
        textTransform: 'none',   
    },
    link: {
        color: theme.palette.primary.main,
        fontSize: '14px',
    },
    linkSpan: {
        fontSize: '15px',
        color: '#63738A',
    },
    maskedEmail: {
        color: theme.palette.primary.main,
        fontWeight: '700',
        textDecoration: 'underline',
    },
    divider: {
        color: '#63738A',
        fontSize: '16px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    troubleSigningIn: {
        color: "#63738A",
        fontSize: '16px',
        paddingBottom: theme.spacing(2),
    },
    helpSection: {
        marginTop: theme.spacing(4),
    },
    infoSectionIcon: {
        transform: 'translateY(-1.3rem)',
        color: theme.palette.primary.main,
    },
    infoSectionText: {
        color: theme.palette.text.primary,
    },
    passRules: {
        margin: '10px 0',
    },
    resetRules: {
        marginBottom: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        transform: 'translateX(-35px)',
    },
    resetRulesList: {
        margin: '0',
        listStyle: 'none',
        '& li': {
            margin: '0',
            paddingLeft: theme.spacing(2),
        },
    },
    bulletFail: {
        display: 'flex',
        '&::before': {
            content: '"\\2022"',
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(3)
        },
    },
    bulletMatch: {
        display: 'flex',
        color: 'green',
        '&::before': {
            content: '"✓"',
            color: 'green',
            marginRight: theme.spacing(1.33),
            marginLeft: theme.spacing(3)
        },
    },
  }));