import createTheme from '@material-ui/core/styles/createTheme';
import { ExtendedTypographyOptions } from '../../customTypes';

const theme = createTheme({
  spacing: 2,
  shadows: [
    'none',
    //Replaced three default shadows to our custom shadows
    '0px 0px 4px 0px #63738A14',
    '0px 1px 2px 0px #63738A29',
    '0px 8px 16px 0px #63738A1F',
    // '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    // '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    // '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  typography: {
    fontFamily: [
      'Spectrum Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    h1: {
      fontSize: '2.488rem',
      fontWeight: 800,
      lineHeight: 1.3,
      letterSpacing: '-0.4px',
    },
    h2: {
      fontSize: '2.074rem',
      fontWeight: 800,
      lineHeight: 1.3,
      letterSpacing: '-0.4px',
    },
    h3: {
      fontSize: '1.728rem',
      fontWeight: 800,
      lineHeight: 1.3,
      letterSpacing: '-0.4px',
    },
    h4: {
      fontSize: '1.488rem',
      fontWeight: 700,
      lineHeight: 1.3,
    },
    h5: {
      fontSize: '1.2rem',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '-0.4px',
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '-0.4px',
    },
    heading: {
      fontSize: '2.986rem',
      fontWeight: 800,
      lineHeight: 1.3,
      letterSpacing: '-0.4px',
    },
    eyebrow: {
      fontSize: '1rem',
      fontWeight: 700,
      letterSpacing: '2px',
      lineHeight: 1.3,
      textTransform: 'uppercase',
      color: '#63738A',
    },
    paragraph1: {
      fontSize: '1.40rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    paragraph2: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
      color: '#63738A',
    },
    subtitle: {
      fontSize: '0.833rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    underline: {
      fontSize: '0.833rem',
      fontWeight: 400,
      lineHeight: 1.5,
      textDecorationLine: 'underline',
    },
    productTitle: {
      fontSize: '0.8rem',
      fontWeight: 500,
      lineHeight: 1.3,
      letterSpacing: '1px',
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        // Custom Button UI
        root: (props: any) => ({
          borderRadius: '2.5rem',
          fontSize: '1rem',
          fontWeight: 500,
          lineHeight: '1.3rem',
          textTransform: 'capitalize',
          letterSpacing: '0.025rem',
          // For Large Buttons
          ...(props.size === 'large' && {
            height: '3.5rem',
          }),
          // For Medium Buttons
          ...(props.size === 'medium' && {
            height: '3rem',
          }),
        }),
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          heading: 'h1',
          eyebrow: 'h6',
          paragraph1: 'p',
          paragraph2: 'p',
          productTitle: 'h6',
          underline: 'h6',
        },
      },
    },
    MuiCheckbox: {
      variants: [
        {
          props: { color: 'default' },
          style: {
            color: '#000',
          },
        },
        {
          props: { color: 'primary' },
          style: {
            color: '#0077AE',
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 0,
          },
          input: {
            '&::placeholder': {
              color: '#000',
              opacity: 1,
            },
          },
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: 0,
          },
        },
      ],
    },
    MuiAlert: {
      variants: [
        {
          props: { variant: 'standard' },
          style: {
            backgroundColor: '#F7F8F9',
            borderLeft: '2px solid #0077AE',
            borderRadius: 0,
            paddingY: 0,
          },
        },
      ],
    },
  },
  palette: {
    type: 'light',
    primary: {
      50: '#DCEEFC',
      100: '#80CCEB', // 10
      200: '#0099D8', //25
      300: '#74C1FB',
      400: '#0062AB',
      500: '#0077AE', // main
      700: '#004970', //75
      800: '#001B33', // 100
      contrastText: '#fff',
    },
    secondary: {
      main: '#00938c', // main
      contrastText: '#fff',
    },
    grey: {
      900: '#63738A66', //80
      800: '#828FA1', //80
      600: '#A1ABB9', //60
      500: '#63738A', // main
      400: '#C1C7D0', //40
      200: '#E0E3E8', //20
      100: '#EFF1F3', // 10
      50: '#F7F8F9', //5
    },
    white: {
      300: '#ffffff33', // main
      500: '#FFFFFF', // main
      600: '#FFFFFF99',
    },
    black: {
      50: '#00000099',
      100: '#0000001A',
      300: '#00000029',
      500: '#000', //main
    },
    success: {
      main: '#008516',
    },
    error: {
      main: '#D6312B',
    },
    info: {
      main: '#0077AE',
    },
    warning: {
      main: '#FAA41A',
    },
    orange: {
      main: '#F57228',
      contrastText: '#AA3D00',
    },
    purple: {
      main: '#E6E5FA',
      contrastText: '#3D30D6',
    },
    teal: {
      main: '#DFF4F3',
      contrastText: '#00756E',
    },
    blue: {
      100: '#F3FBFD',
      200: '#B3E5F5',
      500: '#0088C2'
    },
  },
});

export default theme;
