import React from "react";
import { useStyles } from "../styles/formStyles";
import { Typography, Grid } from "@material-ui/core";
import { KiteIcon } from "@kite/react-kite";
import { contactUsInfo, SINGLESIGNON } from "../utils/types";

const MailIcon = () => {
  return (
    <KiteIcon
      ariaLabel=""
      badge=""
      className=""
      fill="#0073D1"
      icon="ki-mail-f"
      size="12px"
    />
  );
};
const PhoneIcon = () => {
  return (
    <KiteIcon
      ariaLabel=""
      badge=""
      className=""
      fill="#0073D1"
      icon="ki-phone-f"
      size="12px"
    />
  );
};

const ContactUs = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Typography className={classes.cardAccentText}>
          {SINGLESIGNON.accent}
        </Typography>
        <Typography className={classes.cardText} variant="h1">
          {SINGLESIGNON.contactUs}
        </Typography>
        <Typography className={classes.cardSubText} variant="body1">
          {SINGLESIGNON.contactUsRepresentative}
        </Typography>

        <Grid container direction="column" alignItems="flex-start">
          <Grid item>
            <div style={{ display: "flex", paddingBottom: "5px" }}>
              <div style={{ width: "16px", paddingRight: "10px" }}>
                <MailIcon />
              </div>
              <Typography
                className={classes.link}
                component="a"
                href={contactUsInfo.href}
              >
                {contactUsInfo.text}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div style={{ display: "flex" }}>
              <div style={{ width: "16px", paddingRight: "10px" }}>
                <PhoneIcon />
              </div>
              <Typography
                style={{ textDecoration: "underline" }}
                className={classes.link}
              >
                {contactUsInfo.phone}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default ContactUs;
