import React, { useEffect, useState } from "react";
import { Button, Typography, Divider, Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import { styled } from "@material-ui/core/styles";
import { deleteCookie, getCookie } from "../utils/common";
import { useStyles } from "../styles/formStyles";
import { InfoIcon } from "./kiteComponents/IconComponents";
import { PageLoader } from "../themeComponents/Loader";
import { SupportedApplications, SINGLESIGNON } from "../utils/types";
import axios from "axios";
import { API_URLS } from "../configs/aws";

interface LoginResponse {
  code: string;
  expires: string;
  maxAge: string;
  token: string;
  username: string;
}

const ActionContainer = styled("div")({});

const Dashboard: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const redirectUrl = localStorage.getItem("callbackUrl");
  const savedClientId = localStorage.getItem("clientId");
  const authCode = localStorage.getItem("code");

  const [loading, setLoading] = React.useState(false);
  const [logoutError, setLogoutError] = React.useState<any>(null);
  const [newAuthCodeRequired, setNewAuthCodeRequired] = React.useState<any>();
  const [currentUser, setCurrentUser] = useState("");
  const [, setLoginError] = React.useState<any>(null);
  const [prevApplication, setPrevApplication] = React.useState<any>(null);

  const params = new URLSearchParams(location?.search);
  const callbackUrl: any = params.get("callbackUrl");
  const clientId: any = params.get("clientId");

  // Pivot point values
  const newAuthCodeFlag = params.get("newAuthCodeRequired");
  const prevClient = params.get("prC");
  const prevCallback = params.get("prCa");

  const findRequestingApplication = (url: string) => {
    switch (true) {
      case url.includes("localhost"):
        return "localhost";
      case url.includes("clientportal"):
        return "clientportal";
      case url.includes("onespot"):
        return "onespot";
      case url.includes("d22x"):
        return "clientportal";
      case url.includes("d1t5cqnhbypzk"):
        return "clientportal";
      case url.includes("dw2igr6u6bjpx"):
        return "clientportal";
      default:
        return "Your Application";
    }
  };

  const findPrevApplication = (url: string) => {
    switch (true) {
      case url.includes("localhost"):
        return "localhost";
      case url.includes("clientportal"):
        return "Client Portal";
      case url.includes("onespot"):
        return "OneSpot.tv";
      case url.includes("d22x"):
        return "Client Portal";
      case url.includes("d1t5cqnhbypzk"):
        return "Client Portal SAP";
      case url.includes("dw2igr6u6bjpx"):
        return "Client Portal SAP";
      default:
        return "Your Previous Application";
    }
  };

  const backToPrevApp = prevCallback || "Go back";
  const prevApp = findPrevApplication(backToPrevApp || "");

  const requestingApplication = findRequestingApplication(
    redirectUrl || callbackUrl || ""
  );

  useEffect(() => {
    if (callbackUrl && newAuthCodeFlag) {
      setNewAuthCodeRequired(true);
      localStorage.setItem("PREVcallbackUrl", prevCallback || "");
      localStorage.setItem("PREVclientId", prevClient || "");
      setPrevApplication(mapNameFromParam(requestingApplication));
      localStorage.setItem("callbackUrl", callbackUrl);
      localStorage.setItem("clientId", clientId);
    }
    if (callbackUrl && newAuthCodeRequired === false) {
      localStorage.setItem("callbackUrl", callbackUrl);
      localStorage.setItem("clientId", clientId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExchangeTokenForCode = async () => {
    const cognitoToken = getCookie("cognito");
    const application = findRequestingApplication(localStorage.getItem("callbackUrl") || callbackUrl || redirectUrl || "");
    
    await axios
      .post(API_URLS.authCodeURL || "/missing-REACT_APP_AUTH_CODE_URL", {
        token: cognitoToken,
        redirectUrl: callbackUrl || redirectUrl,
        clientId: clientId || savedClientId,
        env: process.env.REACT_APP_ENV || "development",
        application: application === "localhost" ? "clientportal" : application,
      })
      .then((result: any) => {
        const data: LoginResponse = result.data;
        if (data.code) {
          localStorage.setItem("code", data.code);
        }
        if (data.code !== "code&client_id") {
          setNewAuthCodeRequired(false);
          setLoading(false);
        } else {
          setLoading(false);
          setLoginError("Invalid password. Please try again.");
        }
      })
      .catch((error) => {
        setLoading(false);
        setLoginError("An unexpected error occurred. Please try again later.");
      });
  };

  const redirectTo = (url: string) => {
    window.location.href = url;
  };

  const handleLogin = (code: any) => {
    setLoading(true);
    if (code) {
      redirectTo(redirectUrl + "?code=" + code);
      setLoading(false);
      localStorage.removeItem("code");
    } else if (authCode) {
      redirectTo(redirectUrl + "?code=" + authCode);
      setLoading(false);
      localStorage.removeItem("code");
    } else {
      redirectTo(redirectUrl + "?");
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await Auth.signOut();
      deleteCookie("user");
      setLoading(false);
      history.push("/login");
    } catch (error: any) {
      setLogoutError(error.message);
      setLoading(false);
    }
    if (callbackUrl && clientId) {
      localStorage.setItem("callbackUrl", callbackUrl);
      localStorage.setItem("clientId", clientId);
    }
    Object.keys(localStorage).forEach((key) => {
      let allowed = ["callbackUrl", "clientId"];
      if (!allowed.includes(key)) {
        localStorage.removeItem(key);
      }
    });
  };

  useEffect(() => {
    const user = getCookie("user");
    user && setCurrentUser(user);
  }, []);

  const capitalize = (str: string) => {
    return str
      .split(".")
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
      .join(".");
  };

  const cancelNewAuthCode = () => {
    setNewAuthCodeRequired(false);
    localStorage.setItem(
      "callbackUrl",
      localStorage.getItem("PREVcallbackUrl") || ""
    );
    localStorage.setItem(
      "clientId",
      localStorage.getItem("PREVclientId") || ""
    );
    localStorage.removeItem("PREVcallbackUrl");
    localStorage.removeItem("PREVclientId");
    history.push("/");
  };

  const submitNewAuthCode = () => {
    setLoading(true);
    localStorage.removeItem("PREVcallbackUrl");
    localStorage.removeItem("PREVclientId");
    localStorage.setItem("callbackUrl", callbackUrl);
    localStorage.setItem("clientId", clientId);
    handleExchangeTokenForCode();
  };

  const mapNameFromParam = (param: string) => {
    const app = SupportedApplications.find((app) => app.paramVal === param);
    return app?.name;
  };

  const formattedPrevApp =
    prevApp === mapNameFromParam(requestingApplication)
      ? "Your Previous Application"
      : prevApp;

  const handleRefreshCodeWithToken = async () => {
    setLoading(true);
    if (!authCode) {
      await handleExchangeTokenForCode();
      const code = localStorage.getItem("code");
      if (code) {
        await handleLogin(code);
      }
    } else {
      await handleLogin(null);
    }
  };

  return (
    <div className={classes.root}>
      {newAuthCodeRequired ? (
        <ActionContainer className={classes.form}>
          <Typography className={classes.cardAccentText}>
            {SINGLESIGNON.accent}
          </Typography>
          <Typography className={classes.cardText} variant="h1">
            {SINGLESIGNON.welcome}
          </Typography>
          <Typography className={classes.cardSubText} variant="body1">
            {SINGLESIGNON.welcomeSubText}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={submitNewAuthCode}
            className={classes.button + " " + classes.medWidthButton}
          >
            <Typography className={classes.buttonSubText}>
              {SINGLESIGNON.goTo} {mapNameFromParam(requestingApplication)}
            </Typography>
          </Button>
          {logoutError && (
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: "20px", marginBottom: "-12px" }}
            >
              <Grid item style={{ width: "17px", marginRight: "8px" }}>
                <InfoIcon
                  fill="#D6312B"
                  size="10px"
                  icon="ki-caution-circle-f"
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  className={classes.errorMessage}
                >
                  {logoutError}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            className={classes.createNewAccountContainer}
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <Typography
                className={classes.logoutContainer}
                variant="subtitle1"
                onClick={cancelNewAuthCode}
              >
                {prevApplication
                  ? `Go back to ${formattedPrevApp}`
                  : "Sign Out"}
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.troubleSigningIn}>
            {SINGLESIGNON.troubleSigningIn}
          </Typography>
          <Typography>
            <Link to="/forgot-password" className={classes.link}>
              {SINGLESIGNON.forgotPassword}
            </Link>
            <span className={classes.linkSpan}> or </span>
            <Link to="/contact-us" className={classes.link}>
              {SINGLESIGNON.contactUs}
            </Link>{" "}
            <span className={classes.linkSpan}>
              {" "}
              {SINGLESIGNON.forMoreHelp}
            </span>
          </Typography>
          {loading && <PageLoader />}
        </ActionContainer>
      ) : (
        <ActionContainer className={classes.form}>
          <Typography className={classes.cardAccentText}>
            {SINGLESIGNON.accent}
          </Typography>
          <Typography className={classes.cardText} variant="h1">
            {SINGLESIGNON.welcome}
          </Typography>
          <Typography className={classes.cardSubText} variant="body1">
            {SINGLESIGNON.welcomeSubText}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleRefreshCodeWithToken}
            className={classes.button + " " + classes.medWidthButton}
          >
            <Typography className={classes.buttonSubText}>
              {SINGLESIGNON.continueAs} {capitalize(currentUser)}
            </Typography>
          </Button>
          {logoutError && (
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ marginTop: "20px", marginBottom: "-12px" }}
            >
              <Grid item style={{ width: "17px", marginRight: "8px" }}>
                <InfoIcon
                  fill="#D6312B"
                  size="10px"
                  icon="ki-caution-circle-f"
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  className={classes.errorMessage}
                >
                  {logoutError}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            className={classes.createNewAccountContainer}
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <Typography
                className={classes.logoutContainer}
                variant="body2"
                onClick={handleLogout}
              >
                {" "}
                {SINGLESIGNON.signInWithOtherAccount}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Typography className={classes.troubleSigningIn}>
            {SINGLESIGNON.troubleSigningIn}
          </Typography>
          <Typography>
            <Link to="/forgot-password" className={classes.link}>
              {SINGLESIGNON.forgotPassword}
            </Link>
            <span className={classes.linkSpan}> or </span>
            <Link to="/contact-us" className={classes.link}>
              {SINGLESIGNON.contactUs}
            </Link>{" "}
            <span className={classes.linkSpan}>
              {" "}
              {SINGLESIGNON.forMoreHelp}
            </span>
          </Typography>
          {loading && <PageLoader />}
        </ActionContainer>
      )}
    </div>
  );
};

export default Dashboard;
