import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  spacing: factor => `${0.25 * factor}rem`,
  sizing: factor => `${0.25 * factor}rem`,
  typography: {
    fontFamily: `"Reach-Text-Regular","Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 13, 
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: '#007DB3',
      secondary: '#0099d8',
      background: '#00629b',
      backgroundLayout: '#f1f0f0',
      authBackground: '#E6F5FC',
      deepBlue: '#001B33',
    },
    common: {
      white: '#ffffff',
      ashWhite: '#f5f5f5',
      darkGrey: '#333',
    },
    icons: {
      complete: '#198017',
      warning: '#ffca09',
      error: '#b70105',
      file: '#3f90cb',
    },
    button: {
      lightBlue: {
        background: '#007DB3',
      },
      cancel: {
        background: '#e3e3e3',
      },
      success: {
        background: '#19ad43',
      },
      base: {
        background: '#e6e6e6',
      },
      disabled: {
        background: '#f3f3f3',
        text: '#e4dfe8',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      slg: 1050,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
